/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import { CmsPageContainerProps } from 'Route/CmsPage/CmsPage.type';
import { HomePageContainerMapDispatchProps, HomePageContainerProps } from 'SourceRoute/HomePage/HomePage.type';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationState, NavigationType } from 'Store/Navigation/Navigation.type';
import { ReactElement } from 'Type/Common.type';
import { RootState } from 'Util/Store/Store.type';

import {
    HomePageContainerMapStateProps,
    HomePageContainerPropsKeys,
} from './HomePage.type';

export const HomeUk = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-uk" */ 'Component/HomeUk'));
export const HomeEu = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-eu" */ 'Component/HomeEu'));
export const HomeUs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-us" */ 'Component/HomeUs'));
export const HomeRow = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-row" */ 'Component/HomeRow'));
export const HomeCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-ch" */ 'Component/HomeCh'));
export const HomeNo = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-no" */ 'Component/HomeNo'));
export const HomeAu = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-au" */ 'Component/HomeAu'));
export const HomeCa = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "home-ca" */ 'Component/HomeCa'));

/** @namespace Inov8/Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): HomePageContainerMapStateProps => ({
    identifier: state.ConfigReducer.cms_home_page,
});

/** @namespace Inov8/Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): HomePageContainerMapDispatchProps => ({
    // eslint-disable-next-line max-len
    changeHeaderState: (state: NavigationState) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, state)),
});

/** @namespace Inov8/Route/HomePage/Container */
export class HomePageContainer extends PureComponent<HomePageContainerProps> {
    componentDidMount(): void {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: DEFAULT_STATE_NAME,
            isHiddenOnMobile: false,
        });
    }

    containerProps(): Pick<CmsPageContainerProps, HomePageContainerPropsKeys> {
        const {
            changeHeaderState,
            currentUrl,
            match,
            identifier,
        } = this.props;

        return {
            changeHeaderState,
            currentUrl,
            match,
            pageIdentifiers: identifier,
        };
    }

    render(): ReactElement {
        return (
            <div block="HomePage">
                { window.website_code === 'uk_website' && (
                    <HomeUk />
                ) }
                { window.website_code === 'us_website' && (
                    <HomeUs />
                ) }
                { window.website_code === 'eu_website' && (
                    <HomeEu />
                ) }
                { window.website_code === 'row_website' && (
                    <HomeRow />
                ) }
                { window.website_code === 'ch_website' && (
                    <HomeCh />
                ) }
                { window.website_code === 'no_website' && (
                    <HomeNo />
                ) }
                { window.website_code === 'au_website' && (
                    <HomeAu />
                ) }
                { window.website_code === 'ca_website' && (
                    <HomeCa />
                ) }
                { /* <CmsPage { ...this.containerProps() } /> */ }
            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
