/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';
import getStore from 'Util/Store';

import RegularPriceQuery from '../query/RegularPrice.query';
import { roundPrice } from '../util/round';
import { waitForCallback } from '../util/wait';
import { getProductData } from './product';

/** @namespace Scandiweb/Gtm/Data/Cart/formatAttributesToString */
export const formatAttributesToString = async (attributes) => {
    if (!attributes || attributes.length === 0) {
        return '';
    }

    return attributes
        .map(({ option_label, value_label }) => `${option_label}-${value_label}`)
        .join('-');
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemData */
export const getCartItemData = async (item) => {
    const {
        quantity,
        prices: {
            price: {
                value: currentPrice,
                currency,
            } = {},
        } = {},
        product,
        configurable_options = {},
    } = item;

    const isEmptyObject = (obj) => Object.keys(obj).length === 0;

    const item_variant = !isEmptyObject(configurable_options)
        ? await formatAttributesToString(configurable_options) : {};

    const productData = await getProductData(product);
    const query = RegularPriceQuery.getRegularPriceField(product.id);
    const { getRegularPrice: { price: regularPrice } } = await executeGet(prepareQuery(query));
    const discountApplied = regularPrice - currentPrice;

    return {
        ...productData,
        discount: roundPrice(discountApplied),
        currency,
        ...(!isEmptyObject(item_variant) ? { item_variant } : {}),
        price: roundPrice(regularPrice),
        quantity,
    };
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemsData */
export const getCartItemsData = async () => {
    // vvv Wait for cart id
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.id);

    const {
        cartTotals: {
            items = [],
        },
    } = getStore().getState().CartReducer;

    if (items.length === 0) {
        return {};
    }

    return Promise.all(items.map(getCartItemData));
};

/** @namespace Scandiweb/Gtm/Data/Cart/getRemoveFromCartData */
export const getRemoveFromCartData = async (item, currencyCode) => {
    const { price, quantity, discount } = await getCartItemData(item);

    return {
        ecommerce: {
            currency: currencyCode,
            // ^^^ We can not read currencyCode via getStore (Redux limitation) => forced to pass
            value: roundPrice((price * quantity) - discount),

            items: [await getCartItemData(item)],
        },
    };
};

/** @namespace Scandiweb/Gtm/Data/Cart/getAddToCartData */
export const getAddToCartData = async (item, currencyCode) => {
    const { price, quantity, discount } = await getCartItemData(item);

    return {
        currency: currencyCode,
        // ^^^ We can not read currencyCode via getStore (Redux limitation) => forced to pass
        value: roundPrice((price * quantity) - discount),

        items: [await getCartItemData(item)],
    };
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartData */
export const getCartData = async (items) => {
    const {
        cartTotals: {
            prices: {
                grand_total: {
                    value: totalValue,
                    currency,
                } = {},
                coupon_code,
            } = {},
        },
    } = getStore().getState().CartReducer;

    if (!totalValue || !currency) {
        return {};
    }

    return {
        currency,
        value: roundPrice(totalValue),
        ...(coupon_code ? { coupon: coupon_code } : {}),
        items: await Promise.all(items.map(async (item) => getCartItemData(item))),
    };
};
